<template lang="pug">
  .lost-password-page
    a-card.card(dis-hover)
      h2.text-center 不开放使用
</template>

<script>
export default {
  name: 'LostPasswordView'
}
</script>

<style lang="less" scoped>
.card {
  width: 400px;
  margin: -250px -200px;
  position: absolute;
  top: 50%; left: 50%;
}
</style>
